import * as React from 'react';

import type { HeadProps, PageProps } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import { Link } from 'stile-shared/src/components/2020/common/Link';
import { Divider, Prose, Section } from 'stile-shared/src/components/2020/common/Primitives';
import { Column } from 'stile-shared/src/components/2020/layout/Column';
import { CONTACT } from 'stile-shared/src/constants';
import { renderWithLineBreaks } from 'stile-shared/src/utils/renderWithLineBreaks';

import { PageLayout } from 'templates/2020/PageLayout';
import { SEO } from 'templates/2020/SEO';

export function Head(props: HeadProps) {
  return (
    <SEO
      title="Privacy policy"
      description="Stile is committed to protecting your privacy. Learn more about our privacy policies and how we manage data here."
      slug={props.location.pathname}
    />
  );
}

function PrivacyPage(props: PageProps) {
  return (
    <PageLayout {...props}>
      <Section>
        <Prose>
          <h1>Privacy policy</h1>

          <p>
            Stile Education is committed to protecting your privacy. Our education services are
            designed for use by K-12 education institutions, teachers, students and administrators.
            Please note that this is just an overview, and that our full{' '}
            <Link url="/other/terms/">Terms of Service</Link> and{' '}
            <Link url="#privacy-policy--stile">Privacy Policy</Link> contain more details.
          </p>

          <p>
            Stile Education is a proud signatory of the{' '}
            <Link url="https://studentprivacypledge.org/" openInNewTab>
              Student Data Privacy Pledge
            </Link>
            . The Student Data Privacy Pledge is an initiative by K-12 School Service Providers to
            support the effective use of student information and safeguard student data.
          </p>

          <StaticImage
            layout="fixed"
            width={180}
            src="../../../../stile-shared/assets/images/privacy/student_privacy_pledge.png"
            alt="Stile is a signatory of the Student Privacy Pledge"
          />

          <h2 id="frequently-asked-questions">Frequently Asked Questions</h2>

          <h3 id="are-you-advertising-or-selling-data">Are you advertising or selling data?</h3>

          <p>
            No. Stile never has and never will run third party advertisements, or sell any personal
            data from our teachers or students.
          </p>

          <p>We will uphold this commitment even if Stile is acquired or goes bankrupt</p>

          <h3 id="if-youre-not-advertising-then-how-do-you-make-money">
            If you’re not advertising, then how do you make money?
          </h3>

          <p>
            Stile partners directly with schools and districts to help their teachers provide a
            world class science education for every student.
          </p>

          <p>
            <Link url="/set-up-trial/">
              Please reach out if you’re interested in setting up a trial!
            </Link>
          </p>

          <h3 id="does-stile-comply-with-the-ccpa-ferpa-coppa-and-the-australian-privacy-act">
            Does Stile comply with the CCPA, FERPA, COPPA, and the Australian Privacy Act?
          </h3>

          <p>
            Yes! Please see our <Link url="#privacy-policy--stile">full privacy policy</Link> for
            more details.
          </p>

          <h3 id="what-data-do-you-collect-on-students">What data do you collect on students?</h3>

          <p>
            Stile collects students’ responses to quiz questions, polls, class discussions and
            brainstorming sessions. This includes multiple choice, free text answers, and —
            optionally — uploaded files. Feedback and marks left by teachers on student work are
            also collected and stored.
          </p>

          <p>
            Additionally, we record metadata about students’ interactions with Stile, including the
            IP address and User Agent of the student’s browser.
          </p>

          <p>
            Students at schools without a Single Sign On integration will be required to set a Stile
            password.
          </p>

          <p>
            Stile optionally collects student names and email addresses, although setting up
            anonymous accounts with pseudonyms is also possible (our support team can help set this
            up, just reach out to{' '}
            <Link url={CONTACT.email.help.url}>{CONTACT.email.help.text}</Link>).
          </p>

          <p>
            Stile does not collect sensitive data such as student demographics, parent / guardian
            contact details, address, conduct, transportation, medical or criminal records etc.
          </p>

          <h3 id="how-do-you-use-student-data">How do you use student data?</h3>

          <p>
            Stile collects, stores, processes, and shares student data only for the purposes of
            providing and improving our educational services, or as authorized by a District, School
            or a parent / guardian.
          </p>

          <h3 id="how-does-stile-secure-this-data">How does Stile secure this data?</h3>

          <p>
            Stile employs industry best practice to secure your data and prevent access by
            unauthorised parties. This includes encrypting all network communication with strong,
            modern ciphers; storing passwords only in a salted, slow-hashed format; automatically
            restricting access to all uploaded data to only pre-authorised nominated people
            (teachers, school admins etc.); regular third party penetration tests of Stile’s
            systems; and logging all access to Stile’s systems so that we can track down any
            unauthorised or mis-allocated data access.
          </p>

          <p>
            Our security team is continually monitoring for problems, and upgrading Stile’s systems
            to keep abreast of new best practice standards and respond to potential emerging
            threats.
          </p>

          <p>As a result of this work, Stile has never suffered a data breach.</p>

          <p>
            We’re so confident in our security that we encourage security research by permitting
            anyone to test our systems in accordance with our{' '}
            <Link url="https://stileapp.com/vulnerability-disclosure-policy.txt">
              vulnerability disclosure policy
            </Link>
            .
          </p>

          <h3 id="does-stile-have-a-student-data-privacy-agreement-with-my-school-in-the-united-states">
            Does Stile have a Student Data Privacy Agreement with my school in the United States?
          </h3>

          <p>
            We have data privacy agreements with many school districts and are happy to work with
            your school and district to get an agreement in place. Please email us at{' '}
            <Link url={CONTACT.email.privacy.url}>{CONTACT.email.privacy.text}</Link> for more
            information.
          </p>

          <h3 id="can-you-just-change-these-policies-at-any-time-for-any-reason">
            Can you just change these policies at any time for any reason?
          </h3>

          <p>
            No. Stile will not make any material changes to our Privacy Policy or Terms of Service
            that relate to the collection or use of Student Data without first giving notice to the
            school or parent and providing a choice before the Student Data is used in a materially
            different manner than was disclosed when the information was collected.
          </p>

          <h3 id="how-can-i-contact-you">How can I contact you?</h3>

          <p>
            Please reach out with any questions or requests to{' '}
            <Link url={CONTACT.email.privacy.url}>{CONTACT.email.privacy.text}</Link>
          </p>

          <Divider />

          <h2 id="privacy-policy--stile">Privacy Policy – Stile</h2>

          <p>
            Stile Education Pty Ltd and Stile Education Inc (<strong>Stile</strong>,{' '}
            <strong>we</strong>, <strong>us</strong>) is committed to protecting your privacy. This
            policy governs how Stile collects, holds, manages, uses and discloses your personal
            information and should be read together with our <Link url="/other/terms/">Terms</Link>,
            which govern your use of our services.
          </p>

          <ul>
            <li>
              <Link url="#our-services">Our Services</Link>
            </li>
            <li>
              <Link url="#what-is-personal-information">What is Personal Information?</Link>
            </li>
            <li>
              <Link url="#what-personal-information-we-collect">
                What Personal Information we collect
              </Link>
            </li>
            <li>
              <Link url="#sensitive-information">Sensitive Information</Link>
            </li>
            <li>
              <Link url="#how-does-stile-collect-and-store-personal-information">
                How does Stile collect and store Personal Information?
              </Link>
            </li>
            <li>
              <Link url="#disclosure-to-recipients-outside-of-australia">
                Disclosure to recipients outside of Australia
              </Link>
            </li>
            <li>
              <Link url="#why-does-stile-collect-hold-use-and-disclose-personal-information">
                Why does Stile collect, hold, use and disclose Personal Information?
              </Link>
            </li>
            <li>
              <Link url="#links-to-external-websites">Links to external websites</Link>
            </li>
            <li>
              <Link url="#embedding-youtube-videos">Embedding YouTube videos</Link>
            </li>
            <li>
              <Link url="#unsolicited-information">Unsolicited information</Link>
            </li>
            <li>
              <Link url="#access-to-data-within-a-school">Access to data within a school</Link>
            </li>
            <li>
              <Link url="#access-to-data-between-schools">Access to data between schools</Link>
            </li>
            <li>
              <Link url="#direct-marketing">Direct Marketing</Link>
            </li>
            <li>
              <Link url="#security">Security</Link>
            </li>
            <li>
              <Link url="#notifiable-data-breach-scheme">Notifiable Data Breach scheme</Link>
            </li>
            <li>
              <Link url="#deleting-personal-information-from-stile">
                Deleting Personal Information from Stile
              </Link>
            </li>
            <li>
              <Link url="#updating-and-correcting-your-personal-information">
                Updating and correcting your Personal Information
              </Link>
            </li>
            <li>
              <Link url="#requesting-your-personal-information">
                Requesting your Personal Information
              </Link>
            </li>
            <li>
              <Link url="#how-you-can-contact-us-find-out-more-information-or-make-a-complaint">
                How you can contact us, find out more information or make a complaint
              </Link>
            </li>
            <li>
              <Link url="#nevada-privacy-rights">Nevada Privacy Rights</Link>
            </li>
            <li>
              <Link url="#california-privacy-rights">California Privacy Rights</Link>
            </li>
            <li>
              <Link url="#california-privacy-notice">California Privacy Notice</Link>
            </li>
          </ul>

          <h3 id="our-services">Our Services</h3>

          <p>
            Stile provides education services, technology (including hardware) and tools (including
            lab supplies) to education institutions, districts, schools, teachers and students via
            its websites (stileeducation.com and stileeducation.com.au), platform
            (stileapp.com/login), mobile applications, printed materials (including homework and
            study guides) and professional learning services (including events and webinars) (
            <strong>Services</strong>
            ).
          </p>

          <p>
            We provide our Services to individuals and schools in the United States and Australia
            and in doing so, comply with applicable US law, including the Family Educational Rights
            and Privacy Rights Act (<strong>FERPA</strong>) and the California Consumer Privacy Act
            (<strong>CCPA</strong>), and applicable Australian law, including the{' '}
            <em>Privacy Act 1988</em> (Cth) (<strong>Australian Privacy Act</strong>) and the
            Australian Privacy Principles.
          </p>

          <p>
            By using our Services or interacting with Stile, you agree to the collection and use of
            your personal information as set out in this policy. If you do not agree to the terms of
            this policy (as updated from time to time), you should not use our Services or interact
            with us.
          </p>

          <h3 id="what-is-personal-information">What is Personal Information?</h3>

          <p>
            When we use the term “<strong>Personal Information</strong>” in this policy, we refer to
            information or data protected by privacy laws in Australia and USA, as applicable. We
            include information that identifies or relates to an identified individual, or is
            protected as personal information or personal data under applicable US laws. We also
            include “personal information” as defined in the Australian Privacy Act, which means
            information or an opinion about an identified individual, or an individual who is
            reasonably identifiable, whether the information is true or not, and whether recorded in
            a material form or not.
          </p>

          <h3 id="what-personal-information-we-collect">What Personal Information we collect</h3>

          <p>
            The kinds of Personal Information we collect depend on what Services you use and how you
            interact with us, including as set out below. The Personal Information we collect about
            you is yours; we don’t claim any ownership over it.
          </p>

          <p>
            If you provide Personal Information about other individuals to us, you must ensure that
            you have informed those individuals that you are providing their Personal Information to
            us and that you have obtained their consent and advised them of this policy.
          </p>

          <h4 id="when-you-contact-us">When you contact us</h4>
          <p>
            When you communicate with us - for example, to make an enquiry or raise an issue or to
            complete a survey or competition form - via telephone, post, social media, email or an
            online form, we keep a record of that communication, including your name, email address,
            postal address, other contact details and any other information you provide us.
          </p>

          <h4 id="new-staff">New staff</h4>
          <p>
            When we recruit new staff, we collect applicant details such as your name, contact
            details, qualifications and work history. Generally, we will collect this information
            directly from you.
          </p>

          <p>
            We may also collect Personal Information from third parties in ways which you would
            expect, for example, from recruitment agencies or referees you have nominated.
          </p>

          <p>
            Before offering you a position, we may collect additional details required by law, such
            as your tax file number or social security number, superannuation information, and other
            information necessary to conduct background checks to determine your suitability for
            certain positions (for example, positions which involve working with children).
          </p>

          <h4 id="when-you-use-our-services">When you use our Services</h4>
          <p>
            Some of our Services require you to create and activate an account with Stile. When you
            do so, we request that you provide your school name, email address and full name, and to
            create a password. We may request the contact details of staff members at your school,
            such as details of your accounts department and I.T. support staff.
          </p>

          <p>
            Stile does not require students or teachers to provide their real names to use the
            Services. If you are a teacher, you may enter pseudonyms for yourself and your students.
            Some customers provide their students with aliased numeric or random email addresses for
            use with online services, avoiding the need to store identifying information about them.
          </p>

          <h4 id="information-collected-by-schools">Information collected by schools</h4>
          <p>
            Stile provides a flexible interface for schools to collect a range of information from
            their students, such as files, text, photos, videos and more. Select members of the
            Stile staff have access to that information, but only if the school has consented. Stile
            staff access this information to administer Stile’s operational and administrative
            support systems and for no other reason.
          </p>

          <p>
            As we are not aware of the specific nature of this information, we treat it all as
            Personal Information.
          </p>

          <p>
            Schools are responsible for providing required notices and obtaining necessary consents
            from the students’ parents or legal guardians to allow us to collect and process such
            Personal information.
          </p>

          <p>
            In this capacity we are acting as a service provider to the schools under FERPA, the
            CCPA and other privacy laws. Please contact your school if you have any questions about
            the Personal Information being collected and if you want to make a request about your
            Personal Information. If we receive such a request directly, we will notify the school
            about the request and will act upon their instructions in responding (unless we are
            required to respond directly to you by law).
          </p>

          <h4 id="education-records">Education Records</h4>
          <p>
            We may have access to student education records that are subject to the FERPA, 20 U.S.C.
            1232g, et seq. and applicable regulations (“<strong>Education Records</strong>”). This
            information is considered confidential and is protected from disclosure, except with the
            consent of the student or, in the case of a minor, a parent, legal guardian or another
            person authorised to consent on behalf of the student, or as otherwise permitted under
            the FERPA.
          </p>

          <p>
            Where we gain access to Education Records, we do so as a service provider to educational
            institutions. We only use Education Records to provide Services to users pursuant the
            relevant contract with the educational institution. We will not use such information to
            market to users except as permitted by law or by the contract.
          </p>

          <p>
            Except as required or permitted by law, or with consent as noted above, we will not
            disclose or share Education Records with any third party unless:
          </p>

          <ul>
            <li>
              such disclosure is permitted by the terms of our contract with the particular
              educational institution from which we received the Educational Records; and
            </li>
            <li>
              the third party has agreed to maintain the confidentiality of the Education Records to
              the same extent required of Stile under its contract with the educational institution.
            </li>
          </ul>

          <p>
            If you seek access to protected Education Records, we will only retrieve it upon receipt
            of, and in accordance with, written directions from an authorised person and if allowed
            by law. If we receive a court order or lawfully issued subpoena seeking the release of
            such data or information, we will provide notification to relevant users with a copy of
            the court order or subpoena prior to releasing the requested data or information, if
            allowed by law or judicial and/or administrative order.
          </p>

          <p>
            If you are a student or parent/guardian and you would like to request to access, modify,
            refuse further collection of or delete a student’s Personal Information that we have
            been provided under a contract with a school or school district, please contact your
            school or school district with your request, as we may not be able to respond to such
            requests directly under our contractual confidentiality obligations with the schools and
            school districts.
          </p>

          <h4 id="childrens-information">Children’s information</h4>
          <p>
            We handle all Personal Information relating to persons less than 13 years of age (“
            <strong>child</strong>”, “<strong>children</strong>”) in accordance with the U.S.
            Children’s Online Privacy Protection Act (COPPA).
          </p>

          <p>
            Stile relies on each School to obtain and provide appropriate parental/guardian consent,
            make any required disclosures, and to manage the collection and usage of childrens data
            in Stile in accordance with the Children’s Online Privacy Protection Act (COPPA). Stile
            Education is not responsible for obtaining, and does not directly obtain such parental
            consent for collecting information from or about children under the age of 13.
          </p>

          <p>
            If Stile becomes aware that a child’s Personal Information has been collected without
            parental consent, then it will take appropriate steps to delete this information. If you
            are a parent or guardian and discover that your child has a registered account with the
            Stile without your consent, please email Stile at{' '}
            <Link url={CONTACT.email.privacy.url}>{CONTACT.email.privacy.text}</Link> and request
            that Stile delete that child’s Personal Information from its systems.
          </p>

          <h4 id="student-data">Student data</h4>
          <p>
            Schools are responsible for what Student Data is shared — which may include data and
            records relating to student information, performance, or use — and for notifying Stile
            when that Student Data is no longer required by the School and may be deleted.
          </p>

          <p>
            Parents / guardians may request to access, correct, and update a student’s personal
            information, but these requests may require the School’s authorization to update or
            delete information contained in active School accounts. If you have any questions about
            reviewing, modifying, or deleting personal information of a Student, please contact your
            School directly.
          </p>

          <p>
            Stile will delete or permanently anonymise all copies of any Student Data within six
            months of receiving a valid deletion request.
          </p>

          <h4 id="cookies-and-other-tracking-technology">Cookies and other tracking technology</h4>
          <p>
            We (and our service providers) may automatically collect Personal Information about your
            use of our Services through cookies and other tracking technologies, such as your
            browser type and operating system, web pages that you view, links you click, your IP
            address, the length of time you visit our Services, the referring URL when you click
            certain buttons, how far you scroll on a page or the webpage that led you to our
            Services.
          </p>

          <p>
            Some information is stored in your browser after you log into the Stile application
            which identifies your browser and keeps you logged-in. However, this information isn’t
            accessible to other websites and doesn’t let Stile or anyone else track you across the
            web. This information is deleted when you log out of the Stile application. You can use
            incognito mode to make this information temporary.
          </p>

          <p>
            Through our auto-saving technology, information may be collected before you submit a
            form or page on Stile’s Services. If you’ve typed something into Stile, assume it has
            been immediately sent to our servers.
          </p>

          <p>Currently, our systems do not recognize browser “do-not-track” requests.</p>

          <p>
            Our Services do not use Google Analytics, cross-site cookies, or any other tracking
            technologies which monitor your browsing activity across the web. Our marketing website
            (stileeducation.com) does use Google Analytics to better understand usage of that
            website. Students and teachers are not required or expected to use stileeducation.com in
            the course of using the Stile Services.
          </p>

          <p>
            Usage data is never sold to third parties (although it may be transferred in the case of
            a merger, acquisition, reorganization or similar transaction involving Stile or its
            assets or business).
          </p>

          <h4 id="other-individuals-or-circumstances">Other individuals or circumstances</h4>
          <p>
            Stile may collect Personal Information about other individuals, such as service
            providers and contractors to Stile, and other individuals who interact with Stile on a
            commercial basis. The kinds of Personal Information we collect will depend on the
            capacity in which you are dealing with Stile. It may include your name, contact details,
            and information regarding our interactions and transactions with you.
          </p>

          <p>
            If you are participating in an event we are managing or delivering, we may take images
            or audio-visual recordings which identify you. These will never be sold. We will ask for
            your permission prior to displaying and or using these images or audio visual
            recordings.
          </p>

          <h3 id="sensitive-information">Sensitive Information</h3>

          <p>We do not collect any sensitive information.</p>

          <h3 id="how-does-stile-collect-and-store-personal-information">
            How does Stile collect and store Personal Information?
          </h3>

          <p>
            Stile generally collects Personal Information directly from you over the phone, by
            email, over the internet or social media, or in person. We may also collect Personal
            Information about you from other sources, for example your parent or guardian, your
            school, a referee, a recruitment agency or a government agency.
          </p>

          <p>
            We take reasonable steps to protect your personal information from misuse, interference
            and loss and from unauthorised access, modification or disclosure.
          </p>

          <p>
            We use the Amazon Web Services (AWS) to securely store your data. The physical security
            of AWS servers and other infrastructure is tightly controlled according to industry best
            practice. In addition to a strong security focus, AWS has also been designed to be
            resilient to failure, featuring multiple separate power supply systems, independent
            network links and a range of other measures to ensure servers are always available.
          </p>

          <h3 id="disclosure-to-recipients-outside-of-australia">
            Disclosure to recipients outside of Australia
          </h3>

          <p>
            Where the Australian Privacy Act applies to your Personal Information, we will only
            disclose it to recipients outside Australia where we have taken reasonable steps to
            ensure that the overseas recipient does not breach the Australian Privacy Principles in
            relation to your Personal Information -unless we have your consent, or an exception
            under the Australian Privacy Principles applies.
          </p>

          <p>
            Stile Education Pty Ltd may share your Personal Information with its subsidiary Stile
            Education Inc – but only if permitted or required by law. Stile Education Inc is based
            in the US and, if it is required to do so by law, it may disclose your Personal
            Information within the US.
          </p>

          <h3 id="why-does-stile-collect-hold-use-and-disclose-personal-information">
            Why does Stile collect, hold, use and disclose Personal Information?
          </h3>

          <p>
            We collect, hold, use and disclose your Personal Information only in accordance with
            this policy, with your consent (or, in the case of a child, the consent of their parent,
            legal guardian or an authorised school administrator) or in accordance with applicable
            laws.
          </p>

          <p>
            We do not use your Personal Information to build a profile on you to sell to others, or
            to target advertising to you now or in the future. If you close your account with Stile,
            we will no longer use your Personal Information. Except as otherwise permitted or
            required by law, we will not access the Personal Information you have provided us unless
            you ask us to (usually in connection with a support enquiry) or if we’ve got reason to
            believe we need to do so to investigate or prevent a violation of our Terms.
          </p>

          <p>
            We collect, hold, use and disclose Personal Information for purposes reasonably
            necessary to provide you with our Services, including:
          </p>

          <ul>
            <li>to provide you with information, products or services you have requested;</li>
            <li>
              to provide and manage your account, including evaluating and processing your
              application for an account, managing orders and payments and collecting overdue
              amounts;
            </li>
            <li>
              to promote and market our Services to you via SMS or email newsletters (if permitted);
            </li>
            <li>to personalise and customise your experiences with Stile;</li>
            <li>to provide security and prevent fraud;</li>
            <li>to research, develop, improve and expand our Services;</li>
            <li>to inform you of updates and our activities, events, facilities and Services;</li>
            <li>to provide third party information and offers (if permitted);</li>
            <li>
              to use aggregated or de-identified information for the purposes of data analysis,
              research and reporting;
            </li>
            <li>
              to enter into contracts with you or third parties and managing client relationships;
            </li>
            <li>to respond to requests for information, general inquiries and complaints;</li>
            <li>to manage, plan, advertise and administer programs, events and competitions;</li>
            <li>for recruitment purposes and to provide internal services to staff;</li>
            <li>
              to respond to law enforcement and other governmental requests, to defend or exercise
              legal claims and to comply with regulatory or other legal requirements; and
            </li>
            <li>
              for any other use required or permitted by law or any other purpose communicated to
              you at the time that the Personal Information was collected or for which you provided
              your consent.
            </li>
          </ul>

          <h4 id="service-providers">Service providers</h4>
          <p>
            We may disclose your Personal Information to third party vendors, service providers,
            contractors or agents who perform functions on our behalf, such as when Stile
            experiences a technical problem or to ensure that the Services operate in an effective
            and secure manner. If we share your Personal Information with a service provider, we
            ensure that party has agreed to comply with our privacy standards as described in this
            policy.
          </p>

          <h4 id="de-identified-data">De-identified data</h4>
          <p>
            We may share non-personal, de-identified and aggregated information with third parties
            and publicly for marketing, advertising, research or promotional purposes. We will not
            sell your Personal Information to third parties.
          </p>

          <h4 id="legal-disclosure-of-your-data">Legal disclosure of your data</h4>
          <p>
            We also may disclose your Personal Information in order to comply with the law, a
            judicial proceeding, court order, or other legal process, such as in response to a
            subpoena.
          </p>

          <h4 id="affiliates">Affiliates</h4>
          <p>
            We may disclose the Personal Information we collect from you to our affiliates or
            subsidiaries solely for the purpose of providing Services to you; however, if we do so,
            their use and disclosure of your Personal Information will be maintained by such
            affiliates and subsidiaries in accordance with this policy.
          </p>

          <h4 id="business-transfers">Business Transfers</h4>
          <p>
            We may transfer your Personal Information to another entity if we are acquired by or
            merged with another entity, if substantially all of our assets are transferred to
            another entity, or as part of a bankruptcy proceeding. We will ensure to the extent
            permitted by law that any such entity is bound by privacy obligations that are at least
            as strict as those set out in this policy. Student Data will not be used in a materially
            different manner without first giving notice to the school or parent and providing them
            a choice, even in the event of acquisition or merger with another entity.
          </p>

          <h4 id="to-protect-us-and-others">To protect us and others</h4>
          <p>
            We may disclose your Personal Information where we believe it is necessary to
            investigate, prevent, or take action regarding illegal activities, suspected fraud,
            situations involving potential threats to the safety of any person, violations of our
            Terms or this policy, or as evidence in litigation in which we are involved.
          </p>

          <h3 id="links-to-external-websites">Links to external websites</h3>

          <p>
            Stile may include links to external websites on our Services, but we are not responsible
            and will not be liable for the operations or policies of any third-party external
            website.
          </p>

          <h3 id="embedding-youtube-videos">Embedding YouTube videos</h3>
          <p>
            The Stile video widget includes a YouTube search feature to allow easily embedding a
            YouTube video into a Stile lesson. Stile uses YouTube API services to accomplish this,
            which follows Google's privacy policy: https://www.google.com/policies/privacy. No
            personal information about Stile users is shared with YouTube; the video search terms
            entered by the user are sent to YouTube anonymously.
          </p>

          <h3 id="unsolicited-information">Unsolicited information</h3>

          <p>
            If we receive information that we did not request, we will comply with applicable
            privacy obligations, including by destroying or de-identifying the information unless we
            are required to keep it by law.
          </p>

          <h3 id="access-to-data-within-a-school">Access to data within a school</h3>

          <p>
            We provide extensive controls to allow schools to manage who can access the information
            stored on behalf of that school. Such information includes instructions, questions,
            student work, student results and comments teachers have entered about students.
            Accordingly, your Personal Information may be accessible to other users at your school.
          </p>

          <h3 id="access-to-data-between-schools">Access to data between schools</h3>

          <p>
            Stile is designed to keep each school’s data completely isolated. We do not disclose
            school-specific data (such as content, student work and student results) across schools
            even if, as a Stile user, you are a member of more than one. However, if you accept an
            invitation to join another school’s Stile account, we will disclose your full name and
            email address to that school.
          </p>

          <h3 id="direct-marketing">Direct Marketing</h3>

          <p>
            We may use your Personal Information to send you direct marketing if you would
            reasonably expect us to or if you have consent, and provided you have not opted out of
            these communications.
          </p>

          <p>
            We will seek your consent to provide you with direct marketing if we have obtained your
            Personal Information from a third party. Direct marketing may include promotional
            material about us or important updates about our Services. We keep these to a minimum,
            while providing you with information you need or might want to know.
          </p>

          <p>
            We always give you the ability to unsubscribe from direct marketing by utilising the
            “unsubscribe” mechanisms provided in each communication or by emailing us at{' '}
            <Link url={CONTACT.email.privacy.url}>{CONTACT.email.privacy.text}</Link>.
          </p>

          <p>We never send email marketing to student email addresses.</p>

          <h3 id="security">Security</h3>

          <p>
            Stile employs industry best practice to help prevent unauthorised access to or misuse of
            your Personal Information. These safeguards include physical, technical, organisational
            and administrative controls as set forth below:
          </p>

          <ul>
            <li>all network communication between Stile and your web browser is encrypted;</li>
            <li>
              passwords are stored in an irreversibly scrambled form which helps to protect them
              even in the event of a data breach;
            </li>
            <li>
              a sophisticated access control system is built into the core of Stile that manages the
              security of each piece of data you provide us, ensuring that only those who have been
              nominated can access it;
            </li>
            <li>
              only specific Stile staff members can gain access to the data we store on your behalf,
              and all such access is automatically logged. These security logs are regularly audited
              to ensure the customer provided explicit permission for this access to occur; and
            </li>
            <li>
              we log all actions performed on Stile by user and IP address allowing us to track down
              unauthorised access for as long as our logs are kept.
            </li>
          </ul>

          <p>
            If you register for an account, you will be asked to select a password to help protect
            your information and verify your identity before being granted access or being able to
            make corrections to any of your Personal Information. You should never disclose your
            password to anyone. If you believe that your password or your account has been
            compromised, please contact us as soon as possible.
          </p>

          <p>Despite our efforts, no data security measures can guarantee 100% security.</p>

          <p>
            Stile also maintains physical security over paper stores, such as through locks and
            security systems at our premises.
          </p>

          <h3 id="notifiable-data-breach-scheme">Notifiable Data Breach scheme</h3>

          <p>
            Part IIIC of the Australian Privacy Act establishes the Notifiable Data Breaches Scheme
            (<strong>NDB scheme</strong>) in Australia. The NDB scheme sets out obligations for
            notifying affected individuals and the Australian Privacy Commissioner about a data
            breach which is likely to result in serious harm. Where serious harm to affected
            individuals is likely, we will notify those individuals and the Australian Privacy
            Commissioner in accordance with our legal obligations. You may contact our Privacy
            Officer (details set out below) should you require additional information.
          </p>

          <h3 id="deleting-personal-information-from-stile">
            Deleting Personal Information from Stile
          </h3>

          <p>
            We will keep your Personal Information only for as long as required for our business
            purposes and otherwise as required by applicable law. Where we no longer need to keep
            your Personal Information, we will take reasonable steps to destroy or de-identify your
            Personal Information.
          </p>

          <p>
            You may request the deletion of your Personal Information from Stile upon account
            closure. Personal Information will be destroyed or de-identified from our live systems
            within six months from receipt of the request. Note that this Personal Information
            removal includes all Personal Information you or your teachers and students have
            uploaded or provided to Stile that we’ve managed on your behalf, but not our
            account-keeping records on you, which we’ll keep for future reference.
          </p>

          <h3 id="updating-and-correcting-your-personal-information">
            Updating and correcting your Personal Information
          </h3>

          <p>
            We will take reasonable steps to ensure that the Personal Information that we hold is
            accurate, up-to-date and complete. You can update or correct your Personal Information
            at any time by contacting us at{' '}
            <Link url={CONTACT.email.privacy.url}>{CONTACT.email.privacy.text}</Link>. We welcome
            any changes to your Personal Information to keep our records up to date.
          </p>

          <h3 id="requesting-your-personal-information">Requesting your Personal Information</h3>

          <p>
            You are entitled to access Personal Information that we hold about you. If you request
            access to your Personal Information, we will grant your request unless providing you
            with access would unreasonably affect the privacy of others or is otherwise not
            permitted under applicable laws. If we refuse your request to access your Personal
            Information, we will provide you with written reasons for the refusal.
          </p>

          <p>
            A request for access can be made to{' '}
            <Link url={CONTACT.email.privacy.url}>{CONTACT.email.privacy.text}</Link>.
          </p>

          <h3 id="how-you-can-contact-us-find-out-more-information-or-make-a-complaint">
            How you can contact us, find out more information or make a complaint
          </h3>

          <p>
            If you feel that your privacy has not been respected or that we have conducted ourselves
            inconsistently with this policy or the applicable law in respect of your Personal
            Information, or for any other queries, problems, complaints or communication in relation
            to this policy, please address them to the Privacy Officer at{' '}
            <Link url={CONTACT.email.privacy.url}>{CONTACT.email.privacy.text}</Link>. We’ll be in
            touch as soon as practical to address your concerns.
          </p>

          <p>Stile is a product of Stile Education Pty Ltd.</p>

          <Column>
            <div>
              <strong>USA</strong> <br />
              <address>{renderWithLineBreaks(CONTACT.us.address.postal)}</address>
            </div>

            <div>
              <strong>Australia</strong> <br />
              <address>{renderWithLineBreaks(CONTACT.au.address.postal)}</address>
            </div>
          </Column>

          <p>
            You can find out more information about the Australian Privacy Act and the Australian
            Privacy Principles from the Office of the Australian Information Commissioner. The
            Information Commissioner may be contacted at{' '}
            <Link url="http://www.oaic.gov.au">www.oaic.gov.au</Link> or by email at{' '}
            <Link url="mailto:enquiries@oaic.gov.au">enquiries@oaic.gov.au</Link>.
          </p>

          <h3 id="nevada-privacy-rights">Nevada Privacy Rights</h3>

          <p>
            Stile does not sell your Personal Information as that term is defined under Nevada Law.
            However, if you wish to exercise an opt-out right under Nevada law, you may email us at{' '}
            <Link url={CONTACT.email.privacy.url}>{CONTACT.email.privacy.text}</Link>.
          </p>

          <h3 id="california-privacy-rights">California Privacy Rights</h3>

          <p>
            Section 1798.83 of the California Civil Code permits California residents to request
            from a business, with whom the California resident has an established business
            relationship, information related to the Personal Information disclosed by those parties
            to third parties for direct marketing purposes and the names and addresses of the third
            parties with whom the business has shared such information during the immediately
            preceding calendar year. Although we do not disclose your Personal Information to third
            parties for their direct marketing, you may make one request each year by emailing us at{' '}
            <Link url={CONTACT.email.privacy.url}>{CONTACT.email.privacy.text}</Link> or sending a
            letter to:
          </p>

          <p>
            <strong>USA</strong> <br />
          </p>

          {renderWithLineBreaks(CONTACT.us.address.postal)}

          <h3 id="california-privacy-notice">California Privacy Notice</h3>

          <p>
            This California Privacy Notice supplements the Privacy Policy and contains the
            disclosures required under the CCPA.
          </p>

          <p>If you are a California resident, you may have the right to request Stile to:</p>

          <ul>
            <li>disclose if we have collected Personal Information about you;</li>
            <li>
              disclose the categories of Personal Information that we have collected about you (if
              any); the categories of sources from which the Personal Information is collected; the
              business or commercial purpose for collecting or selling Personal Information; and the
              categories of third parties with whom the business shares Personal Information;
            </li>
            <li>
              disclose the specific pieces of Personal Information that we have collected about you
              (if any);
            </li>
            <li>
              delete Personal Information we have collected about you (if you satisfy the conditions
              set out in the CCPA); and
            </li>
            <li>
              not sell the Personal Information we have collected about you (although as noted
              above, we do not sell your Personal Information).
            </li>
          </ul>

          <p>
            You can submit a request under the CCPA by emailing us at{' '}
            <Link url={CONTACT.email.privacy.url}>{CONTACT.email.privacy.text}</Link>. In order to
            respond to a request, we will need to verify your identity. If you have an account with
            us that is password-protected, we may verify your identity through our existing
            authentication practices for your account. If you do not have an account with us, and
            your request concerns “categories” of Personal Information collected, we can request
            from you two data points of Personal Information to verify your identity. If you do not
            have an account with us, and your request concerns specific Personal Information, we can
            request from you at least three data points of Personal Information as well as a signed
            declaration with penalty of perjury to verify your identity. As a California resident,
            you also have the right to designate an agent to exercise these rights on your behalf.
            We may require proof that you have designated the authorized agent to act on your behalf
            and to verify your identity directly with us. Please contact us at{' '}
            <Link url={CONTACT.email.privacy.url}>{CONTACT.email.privacy.text}</Link> for more
            information if you wish to submit a request through an authorized agent.
          </p>

          <p>
            We hereby inform you that we will not discriminate against you if you exercise any of
            your rights under the CCPA; for example, we may not deny you goods or services for that
            reason, or subject you to different prices than those paid by other consumers, unless
            provided otherwise under the CCPA, Federal, or State law.
          </p>

          <p>
            As stated above, we collect the following categories of Personal Information from
            California residents: (a) identifiers; (b) Personal Information categories listed in the
            California Customer Records statute (Cal. Civ. Code § 1798.80(e)); (c) protected class
            information including race, gender and ethnicity if provided by the school; (d)
            commercial information, including records of products or services purchased, obtained,
            or considered from Site; (f) geolocation data in the form of IP Addresses; (g) audio and
            video information in the form of uploads of users (including students and teachers if
            requested by the teacher); (f) internet or other electronic network activity
            information; (h) Non-public education information (per FERPA); and (i) inferences drawn
            from the Personal Information collected through the Services regarding student behavior
            (but only for use by the schools and not for sale or marketing purposes).
          </p>

          <p>
            We collect this information to provide the Services and for the other purposes described
            above. We collect this either from you directly or from school districts that are our
            customers and share this information with school districts and service providers and
            others as set forth above. If we are acting as a service provider to schools in
            connection with the CCPA, we are not responsible for responding directly to requests
            under the CCPA and will forward such requests to the school for direction on a response.
          </p>

          <Divider />

          <em>Last updated: March 2024</em>
        </Prose>
      </Section>
    </PageLayout>
  );
}

export default PrivacyPage;
